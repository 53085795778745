import React, { useState } from "react";
import { Container, Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Footer from "../Componants/Footer";
import NavBar from "../Componants/NavBar";
import RegisterModal from "../Componants/RegisterModal";
import TextmeModal from "../Componants/TextmeModal";
function Home() {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTextClose = () => setShowText(false);
  const handleTextShow = () => setShowText(true);
  return (
    <>
      {showText && (
        <TextmeModal
          showText={showText}
          handleTextClose={handleTextClose}
          handleTextShow={handleTextShow}
        />
      )}
      {show && (
        <RegisterModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
      <NavBar />
      <Container fluid className="p-0">
        <div className="banner text-center text-md-start">
          <div className="row m-0 align-items-center ">
            <div className="col-12 col-lg-6">
              <div className="banne_left">
                <h1 className="mt-5">Welcome to Hedge Rewards </h1>
                <p className="mb-5 pb-4">
                  The future of sports betting is here! Hedge Rewards is the
                  only universal rewards program for in-person sports wagering.
                  Our easy-to-use application platform allows sports betters to
                  scan retail bet slips and convert the amount wagered into
                  reward points. These points can then be redeemed through our
                  online market place for amazing rewards and exclusive perks.
                  <br />
                  <br />
                  Its time to elevate your betting experience and maximize your
                  potential winnings with Hedge Rewards!{" "}
                </p>
                <p className="pt-3 pt-md-4 com_text">
                  <b>App coming soon to IOS and Android</b>
                </p>
                <div className="str_redirect gap-4 gap-lg-5 mb-2 mb-xl-5">
                  <Image
                    className="img-fluid  custom_store"
                    src="/assets/images/playstore.svg"
                    onClick={handleTextShow}
                  />
                  {/* mb-2 mb-sm-2 mb-md-2 mb-xl-5 */}
                  {/* mb-2 mb-sm-2 mb-md-2 mb-xl-5 */}
                  <Image
                    className="img-fluid  custom_store"
                    src="/assets/images/appstore.svg"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 p-0">
              <Image
                className="img-fluid banner_img"
                src="/assets/images/banner.webp"
              />
            </div>
          </div>
        </div>
        <div className="register">
          <Container fluid className="py-4 mt-4">
            <div className="row align-items-center ">
              <div className="col-12 col-lg-7 pe-5 me-5">
                <div className="ml_custom">
                  <h2>Earn 50 Free Reward Points!</h2>
                  <p className="mb-0">
                    As a special bonus, we are offering 50 free reward points to
                    kickstart your account in the app. Simply sign up on our
                    website, and these bonus points will be credited to your
                    Hedge Rewards account. Don't miss out on this limited-time
                    offer!{" "}
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-3 ps-5  ms-xl-5 ms-md-0 ms-sm-0 ms-xs-0">
                <div className="rg_rt my-4">
                  <button className="regBtn" onClick={handleShow}>
                    Sign Up Now !
                  </button>{" "}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <section className="welcome_sec">
        <Container className="my-3 my-md-5">
          <div className="hed-sec">
            <h2 className="text-center">How Hedge Rewards Works </h2>
            <p className="custom_p">
              A revolutionary software platform that is designed with simplicity
              in mind, making it easy for anyone to use. Start earning rewards
              in 3 simple steps!
            </p>
          </div>
          <div className="row mt-3 mt-md-5">
            <div className="col-12 col-md-4 text-center inner-text mb-3">
              <Image
                className="img-fluid wd_custom1"
                src="/assets/images/step1.svg"
              />
              <div className="position_djst">
                <h3>Place a Bet</h3>
                <p>
                  Make a sports bet in person at any nearby Sportsbook. Please
                  bet responsibly.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4 text-center inner-text mb-3">
              <Image
                className="img-fluid wd_custom1"
                src="/assets/images/step2.svg"
              />
              <div className="position_djst">
                {" "}
                <h3>Scan your Bet Slip</h3>
                <p>
                  Open the camera feature through the app to scan the front end
                  of your bet slip.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-4 text-center inner-text mb-3">
              <Image
                className="img-fluid wd_custom1"
                src="/assets/images/step3.svg"
              />
              <div className="position_djst">
                {" "}
                <h3>Earn Rewards</h3>
                <p>
                  Every time you scan a bet slip you earn reward points. Cash
                  these points out for prizes in our marketplace!
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <div className="light-bgcolor py-3 py-md-5 ">
        <div className="seal_img">
          <Image className="img-fluid" src="/assets/images/seal.webp" />
        </div>
        <Container className="">
          <div className="hed-sec">
            <h2 className="text-center">Hedge Rewards Features </h2>
            <p className="wrap-text">
              Our platform is proud to introduce a range of patent-pending
              features that will re-invent the way you earn and redeem points,
              taking your rewards experience to unmatched heights.
            </p>
          </div>
          <div className="row mt-3 mt-md-5 justify-content-center">
            <div className="col-12 col-md-5 text-center inner-text inner-text-2 mb-3">
              <Image
                className="img-fluid wd_custom1"
                src="/assets/images/list2.svg"
              />
              <div className="position_djst">
                <h3>Create an Account</h3>
                <p>
                  It's completely free and takes only a few minutes. Your
                  personal information is safe and secure with our advanced
                  security features.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 text-center inner-text inner-text-2 mb-3">
              <Image
                className="img-fluid wd_custom1"
                src="/assets/images/sc1.svg"
              />
              <div className="position_djst">
                <h3>Scanning Technology</h3>
                <p>
                  Our cutting-edge technology will scan your receipt and
                  automatically add points to your account.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className="my-3 my-md-5">
        <div className="hed-sec">
          <h2 className="text-center">Hedge Rewards Features</h2>
          <p>
            Our innovative features have changed the way you engage retail
            sports betting. Hedge Rewards is the ultimate tool for success. Win
            or lose, we reward you for your passion.
          </p>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col-12 col-md-4 text-center inner-text mb-3">
            <Image
              className="img-fluid wd_custom1"
              src="/assets/images/3-1.svg"
            />
            <div className="position_djst">
              <h3>Online Marketplace</h3>
              <p>
                Our marketplace is designed to provide you with a seamless and
                gratifying experience as you redeem your hard-earned points for
                a wide range of enticing products, services, and experiences.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center inner-text mb-3">
            <Image
              className="img-fluid wd_custom1"
              src="/assets/images/3-2.svg"
            />
            <div className="position_djst">
              {" "}
              <h3>Find Local Sportsbooks</h3>
              <p>
                Find local sportsbooks within a 100-mile radius. Discover nearby
                establishments where you can place your bets, enjoy the
                electrifying atmosphere of a sportsbook, and engage with fellow
                enthusiasts.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center inner-text mb-3">
            <Image
              className="img-fluid wd_custom1"
              src="/assets/images/3-3.svg"
            />
            <div className="position_djst">
              {" "}
              <h3>Track your Bets</h3>
              <p>
                Every bet slip scanned and accepted will be saved to your
                account as a tool for personal financial tracking and
                documentation.
              </p>
            </div>
          </div>
        </div>
      </Container>

      <div className="primary-bg sec_testimonials">
        <Container className="py-3 py-md-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-12 ">
              <div className="hed-sec">
                <h2 className="text-center text-white mb-4">
                  Hedge Rewards Preview{" "}
                </h2>
                {/* <p className='text-white'>Pellentesque urna erat, sagittis vestibulum lacus in, scelerisque tincidunt mi. Donec purus risus, Pellentesque urna erat, sagittis vestibulum lacus in, scelerisque </p> */}
              </div>
              <Carousel
                controls={false}
                indicators={false}
                className="vid_carousel"
              >
                <Carousel.Item interval={1000}>
                  {/* <Image className="img-fluid" src="/assets/images/demo.webp" /> */}

                  <div className="video-player-container">
                    <video controls className="video-player">
                      <source
                        src={
                          "https://lcards.newagesmb.com/uploads/react/5132aece-9bb5-49fd-b24f-2e15729cce12.mp4"
                        }
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
      <div>
        {/* <Container className='py-3 py-md-5'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-12 col-lg-4 text-center text-md-start'>
                            <p className='mb-2'>What are you waiting for?</p>
                            <h3 className='primary-text'>Scan to Download</h3>
                        </div>
                        <div className='col-12 col-lg-4 text-center'>
                            <Image className='img-fluid' src="/assets/images/qr.webp" />
                        </div>
                    </div>
                </Container> */}
      </div>

      <Footer />
    </>
  );
}

export default Home;
