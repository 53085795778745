import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import axios from '../../utils/Axios'
import axios from '../../utils/Axios'
// import axios from 'axios'

const initialState = {
    loading: false,
    user: [],
    ContactUs:[]
}

// ASYNC FUNCTION START

export const RegisterUser = createAsyncThunk(
    'auth/Register',

    async (obj, { rejectWithValue }) => {
    //    console.log(obj, 'Lpage');
        try {
            const res = await axios.post(`website-registration`, {
                "active": true,
                "first_name": obj?.f_Name,
                "last_name": obj?.l_Name,
                "email":obj?.email
            })
            return res
            // if (res.status === 200) {
            //     (res.data); 
            // } else {
            // (res); 
            // }


        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)
export const ContactUs = createAsyncThunk(
    'auth/contactUs',

    async (obj, { rejectWithValue }) => {
    //    console.log(obj, 'contactPage');
        try {
            const res = await axios.post(`contact-us`,
             {
                "active": true,
                "name": obj?.f_Name,
                "email": obj?.email,
                "message": obj?.message
            }
            )
            return res


            
            // if (res.status === 200) {
            //     (res.data); 
            // } else {
            // (res); 
            // }


        } catch (error) {
            return rejectWithValue(error.response.data.message)

        }

    }
)


// ASYNC FUNCTION END

const MainSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: {
        [RegisterUser.pending]: (state) => {
            state.loading = true
            
        },
        [RegisterUser.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.user = payload?.data
            
        },
        [RegisterUser.rejected]: (state) => {
            state.loading = false
            
        },
        [ContactUs.pending]: (state) => {
            state.loading = true
            
        },
        [ContactUs.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.ContactUs = payload?.data
            
        },
        [ContactUs.rejected]: (state) => {
            state.loading = false
            
        },
       
    }
});

// export const { } = MainSlice.actions

export default MainSlice.reducer