import React, {useEffect, useState} from 'react'
import {  Image   } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SideMenu from './SideMenu';
function NavBar() {
    const [scroll, setScroll] = useState(false);
    const [openSideBar, setOpenSideBar] = useState(false)
    const handleShowSideBar = () =>{
        setOpenSideBar(true)
    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 150);
        });
    })
  return (    
    <>
    <div className={"menuNav " + (scroll ? "stickyMenu animated fadeInDown" : "staticMenu")}>   
    <div className="container_fl">     
      <div className="nav_item">
        <div className="nav_left">
          <Link to='/'>
          <Image className='img-fluid mb-2 me-md-2' src="/assets/images/logo.svg"/>
          </Link>
           
        </div>
        <div className="nav_right">
          <div className="hambergerMenu scrollHamberger showOnMobileHam" onClick={handleShowSideBar}>
              <span></span>
              <span></span>
              <span></span>
          </div>
        </div>
      </div>
    </div>
    </div>
    
        <SideMenu handleShowSideBar={handleShowSideBar} openSideBar={openSideBar} setOpenSideBar={setOpenSideBar}/>
    
  
    </>
  )
}

export default NavBar