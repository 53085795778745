import { useState } from "react"
import { Link } from "react-router-dom"

export default function SideBarItem({item}){
    const [open] = useState(false)   
    // console.log(open);
    
        return (
            <>
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div className="sidebar-title" >
                        <Link className="added_link" to='/'>
                        <span >
                            Home
                        </span> 
                        </Link>
                        
                        <i className="bi-chevron-down toggle-btn"></i>
                    </div>
                    {/* <div className="sidebar-content">
                        { item.childs.map((child, index) => <div key={index}><SideBarItem  item={child} /></div>) }
                    </div> */}
                </div>
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div className="sidebar-title" >
                        <Link className="added_link" to='/faqs'>
                        <span>
                            FAQ
                        </span> 
                        </Link>
                       
                        <i className="bi-chevron-down toggle-btn"></i>
                    </div>
                    {/* <div className="sidebar-content">
                        { item.childs.map((child, index) => <div key={index}><SideBarItem  item={child} /></div>) }
                    </div> */}
                </div>
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div className="sidebar-title" >
                        <Link className="added_link" to='/contact'>
                        <span>
                            Contact Us
                        </span> 
                        </Link>
                        
                        <i className="bi-chevron-down toggle-btn"></i>
                    </div>
                    {/* <div className="sidebar-content">
                        { item.childs.map((child, index) => <div key={index}><SideBarItem  item={child} /></div>) }
                    </div> */}
                </div>
            </>
        )
    
    // else
    // {
    //     return (
    //         <>  {
    //                 item?.path && 
    //             <>
    //                 <a href={item?.path || "#"} className="sidebar-item plain">
    //                     {item?.title}
    //                 </a>
    //             </>
    //             }
                
    //             {
    //                 item?.input && 
    //                 <>
    //                     <div className="check_filter">
    //                         <span><input type="checkbox" id={item?.input} /></span>
    //                         <label htmlFor ={item?.input}>{item?.label}</label>
    //                     </div>
    //                 </>
    //             }
    //             {
    //                 item?.code &&  
    //                 <>
    //                     <div className="color-item">                        
    //                         <span className={item?.color === "White" ? "has-border" : "no-border"}><i style={{backgroundColor: item.code}}></i></span>
    //                         <span>{item?.color}</span>   
    //                     </div>
    //                 </>
    //             }
                
    //         </>
            
            
    //     )
    // }
}