import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
function TextmeModal({showText,handleTextClose}) {
  return (
    <div>
        <Modal show={showText} onHide={handleTextClose}>    
        <Modal.Header closeButton className='mod_hdr'>
        <Modal.Title>Text me the App</Modal.Title>

        </Modal.Header>
        <Modal.Body>
        <div className="body_modal">
                    <Form>
                        <fieldset>
                            <Form.Group className="mb-3">
                                <div className="c_fld">
                                    <span className='c_code'>+91</span>
                                    <Form.Control type="text" placeholder="Phone Number" />
                                </div>
                            </Form.Group>
                            <div className="col-md-8 text-center m-auto">
                                <Button type="submit" onClick={handleTextClose}>Text me</Button>
                            </div>
                        </fieldset>
                    </Form>
                </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TextmeModal