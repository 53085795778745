import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <div>
            {/* <footer>
                <Container className=' py-5'>
                    <div className='fc_row'>
                        <div className='fc_left'>
                            <ul>
                                <li className='me-4'><a href="/terms-conditions">Terms & Conditions</a></li>
                                
                                <li className='me-4'><a href="/privacy">Privacy Policy</a></li>
                                <li className='me-0'><Link to='/faqs'>FAQ</Link> </li>
                            </ul>
                        </div>
                        <div className='fc_right'>
                            <ul>
                            <li className='me-xl-5 me-md-0 me-sm-0 me-xs-0 mt-1'>
                            <Link className='btn_border  px-4   ' to='/contact'>
                                Contact Us
                                </Link>
                                </li>


                                

                            </ul>
                        </div>
                    </div>

                </Container>
            </footer> */}
            <footer class="footer">
  {/* <div class="container" style={{padding:'10px'}} > */}
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center  footer-secc" >
      <div   >
        <ul class="list-inline "  >
          <li class="list-inline-item text-white"><a className='list-inline-item' href="/terms-conditions">Terms & Conditions</a></li>
          <li class="list-inline-item text-white ms-3"><a className='list-inline-item' href="/privacy"> Privacy policy</a></li>
          <li class="list-inline-item text-white ms-3"><a className='list-inline-item' href="/faqs"> FAQ</a></li>
        </ul>
      </div>
      <div  >
      <li className='ct-Btn'><a href="/contact" className='btn_border  px-3 py-0 inside_con p-2 ct-Btn list-inline-item '>Contact Us</a></li>
      </div>
    </div>
  {/* </div> */}
</footer>

{/* <footer class="footer ">
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center  footer-secc">
    <div>
      <ul class="list-inline m-0">
      <li class="list-inline-item text-white"><a href="#">Terms & Conditions</a></li>
          <li class="list-inline-item text-white">Privacy policy</li>
          <li class="list-inline-item text-white">FAQ</li>
      </ul>
    </div>
    <div>
      <a href="#" class="border border-white rounded p-2 text-white">Link</a>
    </div>
  </div>
</footer> */}




        </div>
    )
}

export default Footer