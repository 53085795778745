import React from 'react'
import NavBar from '../Componants/NavBar'
import Footer from '../Componants/Footer'

function Privacy() {
    return (
        <div>
            <NavBar />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-12 col-lg-10">
                        <div className="pg_inside text-left">
                            <h2>Privacy Policy</h2>
                            <h5>
                                Effective Date: [Date]
                                <br /> <br />
                                Thank you for visiting hedgerewards.com (the &quot;Website&quot;). This Privacy Policy is
                                intended to inform you about how we collect, use, and disclose your personal
                                information when you visit our Website and when you use our mobile application
                                (&quot;App&quot;), which can be downloaded from the App Store and Google Play. Please take a
                                moment to read this Privacy Policy carefully.</h5>
                            <div className="pg_con">
                                <h3>1. Information We Collect</h3>
                                <p>1.1 Information You Provide When you visit our Website or download our App, we may
                                    collect personal information that you voluntarily provide to us. This includes the
                                    following:.</p>
                                <div className='m-3'>
                                    <li>First name</li>
                                    <li>Last name</li>
                                    <li>Email address</li>
                                </div>

                                <p>We collect this information when you sign up for bonus points in the App.</p>
                                {/* <p> Vivamus interdum arcu at quam faucibus, efficitur porta quam condimentum. Nullam vel dictum nibh, id congue turpis Nunc euismod in nisi quis imperdiet. Suspendisse tempus auctor nulla. Praesent libero ante, tempus ac dui sed, suscipit sagittis diam. Pellentesque vitae faucibus nulla. Quisque tempus ipsum ex, vitae rutrum urna laoreet quis. Mauris non egestas felis, ac blandit dui. Duis mollis ac orci vitae egestas. Sed in mauris at eros commodo mollis. Nunc sapien nibh, faucibus eget sodales id, hendrerit ac ex. Mauris libero sapien, cursus vestibulum erat vitae, pretium semper eros. Aenean lacus eros, accumsan at placerat quis, mollis sit amet erat. unc in lectus suscipit feugiat ac vitae nunc. Pellentesque vel arcu sapien. Suspendisse id scelerisque arcu, non mollis felis. Nam vitae facilisis tellus, et suscipit nulla. Cras placerat, ipsum eu finibus consequat, urna neque semper enim, nec pulvinar est velit eu nibh. Vestibulum bibendum nibh ac vehicula dapibus. Pellentesque mattis purus et nulla mollis commodo. Vivamus imperdiet lect</p> */}
                                <h3>2. How We Use Your Information</h3>
                                <p>2.1  Provision of Services We use the information you provide to deliver and improve our
                                    services, including providing you with bonus points and delivering personalized rewards
                                    through the App.</p>
                                <p>2.2 Communication We may use your email address to send you promotional offers,
                                    updates, and other relevant information about our services. You can opt out of receiving
                                    these communications at any time by following the instructions provided in the email.</p>
                                <p>2.3 Analytics and Improvements We may use the information we collect to analyze user
                                    behavior, improve our services, and optimize your experience on the Website and App.
                                    This includes, but is not limited to, tracking user interactions, monitoring user
                                    preferences, and identifying potential issues.</p>


                                <h3>3. How We Protect Your Information?</h3>
                                <p>We implement appropriate security measures to protect your personal information from
                                    unauthorized access, alteration, disclosure, or destruction. However, please note that
                                    no method of transmission over the internet or electronic storage is completely secure,
                                    and we cannot guarantee absolute security of your information..</p>

                                <h3>4. Disclosure of Your Information</h3>
                                <p> We do not sell, trade, or otherwise transfer your personal information to third parties for
                                    marketing purposes. However, we may disclose your information to trusted third-party
                                    service providers who assist us in operating our business and providing our services,
                                    subject to their agreement to keep the information confidential.</p>
                                <p> We may also disclose your information if required by law, to comply with a court order or
                                    other legal process, or to protect our rights, property, or safety, or the rights, property, or
                                    safety of others..</p>
                                <h3>5. Third-Party Links and Services</h3>
                                <p>Our Website and App may contain links to third-party websites or services that are not
                                    operated by us. Once you leave our Website or App, we have no control over, and
                                    assume no responsibility for, the privacy practices or content of these third-party
                                    websites or services. We encourage you to read the privacy policies of any third-party
                                    websites or services you visit.</p>
                                <h3>6. Children&#39;s Privacy</h3>
                                <p>Our Website and App are not intended for use by individuals under the age of 13. We
                                    do not knowingly collect personal information from children under 13. If you are a parent
                                    or guardian and believe that your child has provided us with personal information,
                                    please contact us immediately. If we become aware that we have collected personal
                                    information from a child under 13 without parental consent, we will take steps to remove
                                    that information from our servers.</p>
                                <h3>7. Updates to this Privacy Policy</h3>
                                <p>We reserve the right to update this Privacy Policy from time to time to reflect changes in
                                    our privacy practices. We will post the revised Privacy Policy on this page and indicate
                                    the effective date of the update. Your continued use of our Website or App after the
                                    effective date constitutes your acceptance of the revised Privacy Policy.</p>
                                <h3>8. Contact Us</h3>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at
                                    info.hedgerewards.com</p>
                                <h3 className='text-center'>By using our Website or App, you consent to the collection, use, and disclosure of your
                                    personal information as described in this Privacy Policy.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy